import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import '../public/reset.css' //清除默认


// 共享仓库
import store from './store'
app.use(store)

// 路由
import router from './router'
app.use(router)

// 请求
import request from './utlis/request';
app.config.globalProperties.axios = request;


// ElementPlus组件库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)





app.mount('#app')
