import axios from 'axios';

const instance = axios.create({
    baseURL: "https://laraveladmin.meibohate.com/api/",
    timeout: 5000
})

// 请求拦截器
instance.interceptors.request.use(config => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    // let token = localStorage.getItem('token');
    // if (token) {
    //     config.headers["Authorization"] = token;
    // }
    return config;
}, err => {
    return Promise.reject(err);
})

// 响应拦截
instance.interceptors.response.use(res => {
    return res.data;
}, err => {
    return Promise.reject(err);
})

export default instance;