import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {path: '/',                   name: 'Index',          component:()=>import("../views/index.vue"),  meta:{title:'首页'},
    children:[
      {path: '',                name: 'Home',           component:()=>import("../views/home.vue"),  meta:{title:'关于'}},
      {path: '/about',          name: 'About',          component:()=>import("../views/about.vue"),  meta:{title:'关于'}},
      {path: '/territory',      name: 'Territory',      component:()=>import("../views/territory.vue"),  meta:{title:'领域'},},
      {path: '/territoryDetail',name: 'territoryDetail',component:()=>import("../views/territoryDetail.vue"),meta:{title:'领域详情'},},
      {path: '/news',           name: 'News',           component:()=>import("../views/news.vue"),  meta:{ title:'新闻中心'}},
      {path: '/videos',         name: 'Videos',         component:()=>import("../views/videos.vue"),  meta:{title:'视频展示'  }},
      {path: '/contact',        name: 'Contact',        component:()=>import("../views/contact.vue"),  meta:{title:'联系集团'  }},
      {path: '/newsdetail',     name: 'newsDetail',     component:()=>import("../views/newsdetail.vue"),  meta:{title:'新闻详细中心'}},
      {path: '/product',        name: 'Product',        component:()=>import("../views/product.vue"),  meta:{title:'产品中心'}},
      {path: '/productDetail',  name: 'ProductDetail',  component:()=>import("../views/productDetail.vue"),  meta:{title:'产品中心'}},
    ]
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
