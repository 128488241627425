<template>
   <el-config-provider :locale="locale">
        <router-view/>
    </el-config-provider>
</template>
<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default {
  data() {
    return {
      locale:zhCn,
    }
  },
}
</script>
<style lang="scss">
.w{width: 1140px;margin: 0 auto;}
</style>
